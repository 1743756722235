import React from "react";
import NavBar from "../Components/Nav/NavBar";
import { static_url } from "../constants";
import { homepage_media, logoA_file, logoB_file, logoFire_file } from "./HomeConstants";
import "./HomeHeader.scss";



function HomeHeaderLogo() {
    return (
        <div className="home_header_title">
            <div className="home_logo home_header_title_elm">
                <img src={logoA_file} className="logo_text" alt="Smokin' Fire"></img>
                <img src={logoFire_file} className="logo_fire" alt=""></img>
                <img src={logoB_file} className="logo_text" alt="Productions"></img>
            </div>
            <div className="text_white perfect_practice home_header_title_elm main_text">
                "Perfect Practice Makes Perfect Performance"
            </div>
        </div>
    );
}

function HomeHeaderImage() {
    const img_file = static_url +  homepage_media + "LookingDownTriPod.jpg";
    const alt_text = "";
    return (
        <div className="home_header_img_container">
            <div className="home_image_overlay"></div>
            <img className="home_header_img" src={img_file} alt={alt_text}></img>
        </div>
    );
}

export default function HomeHeader() {
    return (
        <div className="full_home_header">
            <NavBar/>
            <HomeHeaderLogo />
            <HomeHeaderImage />
        </div>
    );
}