import React from "react";
import {useRoutes} from "react-router-dom";
import {routes} from "../constants";
import ContactPage from "../Homepage/contact_us/ContactPage";
import HomePage from "../Homepage/HomePage";

export default function HomeRoutes() {
    const element = useRoutes([
        { path: routes.contact, element: <ContactPage /> },
        { path: routes.home, element: <HomePage /> }
    ]);
    
    // The returned element will render the entire element
    // hierarchy with all the appropriate context it needs
    return element;
}
