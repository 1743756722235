import React from "react";
import { FormSubmit } from "../../Components/FormSubmit";
import { FormInput } from "../../Components/Form/FormInput";
import FormTextArea from "../../Components/Form/FormTextArea";
import "./ContactUsFormContent.scss";

type ContactUsFormContentProps = {
    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    company: string;
    setCompany: React.Dispatch<React.SetStateAction<string>>;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    verifyEmail: string;
    setVerifyEmail: React.Dispatch<React.SetStateAction<string>>;
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
};
export function ContactUsFormContent(props: ContactUsFormContentProps) {

    return (
        <>
            <FormInput
                placeholder="Your Name"
                name="name"
                validationField="name"
                value={props.name}
                onChange={(event) => { props.setName(event.target.value); }} />
            <FormInput
                placeholder="Your Company"
                name="company"
                validationField="company"
                value={props.company}
                onChange={(event) => { props.setCompany(event.target.value); }} />
            <FormInput
                placeholder="Your Email"
                name="email"
                validationField="email"
                value={props.email}
                onChange={(event) => { props.setEmail(event.target.value); }} />
            <FormInput
                placeholder="Verify Email"
                extraClass="verify_email"
                name="verify_email"
                validationField="verify_email"
                value={props.verifyEmail}
                onChange={(event) => { props.setVerifyEmail(event.target.value); }} />
            <FormTextArea
                placeholder="Your Message"
                name="message"
                validationField="message"
                value={props.message}
                onChange={(event) => { props.setMessage(event.target.value); }} />
            <FormSubmit
                initialMessage="Send Message" />
        </>
    );
}
