import React from "react";
import { useState } from "react";
import FormProvider from "../../Providers/FormProvider";
import { ContactUsFormContent } from "./ContactUsFormContent";

export function ContactUsForm() {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [verifyEmail, setVerifyEmail] = useState("");
    const [message, setMessage] = useState("");
    const formID = "contact_us_form";

    return (
        <FormProvider
            type="POST"
            url="/api/contact/"
            id={formID}
            data={{
                name: name,
                company: company,
                email: email,
                verify_email: verifyEmail,
                message: message,
            }}
            afterSuccess={(results) => {}}
        >
            <ContactUsFormContent
                name={name}
                setName={setName}
                company={company}
                setCompany={setCompany}
                email={email}
                setEmail={setEmail}
                verifyEmail={verifyEmail}
                setVerifyEmail={setVerifyEmail}
                message={message}
                setMessage={setMessage} />
        </FormProvider>
    );
}

