import React from "react";
import { static_url } from "../../constants";
import { getClassIfNotHash } from "../../functions";
import { homepage_media } from "../HomeConstants";
import { AboutUsImgAndText } from "./AboutUsImgAndText";
import { hashRoutes } from "./hashRoutes";


export function AboutUsSafety() {
    const ladder_img = static_url + homepage_media + "LadderInTunnel.jpg";
    const classes = getClassIfNotHash(hashRoutes.safety, "hidden", false);

    return (
        <div className={classes}>
            <AboutUsImgAndText
                src={ladder_img}
                alt="Experience Safety Officers"
                title="Experienced Safety Officers"
                body="Our safety officers are competent in fall protection, respiratory, and scaffolding. They are OSHA 10, 30, and 510 certified and have been trained specifically for power generation projects." />
        </div>
    );
}
