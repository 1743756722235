import React from "react";
import AboutUs from "./about_us/AboutUs";
import { MissionStatement } from "./MissionStatement";

export default function HomePage() {
    return (
        <div>
            <MissionStatement />
            <AboutUs />
        </div>
    );
}