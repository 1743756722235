import React from "react";
import { static_url } from "../../constants";
import { getClassIfNotHash } from "../../functions";
import { homepage_media } from "../HomeConstants";
import { AboutUsImgAndText } from "./AboutUsImgAndText";
import { hashRoutes } from "./hashRoutes";

export function AboutUsRescue() {
    const truck_img = static_url + homepage_media + "RescueTruck.jpg";
    const classes = getClassIfNotHash(hashRoutes.rescue, "hidden", false);

    return (
        <div className={classes}>
            <AboutUsImgAndText
                src={truck_img}
                alt="State-of-the-Art Equipment"
                title="Competent Rescue Crew with State-of-the-Art Equipment"
                body="Our team members are current and active full-time firefighters with real-world experience. In addition, they are certified extrication, structural collapse, confined space, rope rescue, and haz-mat technicians." />
        </div>
    );
}
