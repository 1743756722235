import React from "react";
import "./AboutUsImgAndText.scss";

type AboutUsImgAndTextProps = {
    src: string;
    alt: string;
    title: string;
    body: React.ReactNode;
};
export function AboutUsImgAndText(props: AboutUsImgAndTextProps) {
    return (
        <div className="about_us_img_container">
            <img className="about_us_img" src={props.src} alt={props.alt}></img>
            <div className="about_us_img_text text_white">
                <div className="about_us_title main_text">{props.title}</div>
                <div className="about_us_text"><span>{props.body}</span></div>
            </div>
        </div>
    );
}
