import React from "react";
import { ContactUsForm } from "./ContactUsForm";
import "./ContactPage.scss";

export default function ContactPage() {
    return (
        <div className="text_white contact_us">
            <div className="main_text contact_us_message">
                {"Send us your contact information, and we will\nget in touch with you as soon as possible!"}
            </div>
            <ContactUsForm />
        </div>
    );
}