import React from "react";
import "./MissionStatement.scss";

export function MissionStatement() {
    return (
        <div className="mission_statement text_white main_text">
            Providing training, safety, and rescue services to industries.
        </div>
    );
}
