import React from "react";
import ReactDOM from "react-dom";
import HomeHeader from "../Homepage/HomeHeader";
import Main from "../Main";
import HomeRoutes from "../Routes/HomeRoutes";
import { PageOptions } from "../constants";
import AppProvider from "../Providers/AppProviders";

function Home() {
    return (
        <AppProvider page={PageOptions.home}>
            <Main
                header={<HomeHeader/>}
                body={<HomeRoutes />}
            />
        </AppProvider>
    );
}

ReactDOM.render(
    <Home />,
    document.getElementById("root")
);