import React from "react";
import { static_url } from "../../constants";
import { getClassIfNotHash } from "../../functions";
import { homepage_media } from "../HomeConstants";
import { AboutUsImgAndText } from "./AboutUsImgAndText";
import { hashRoutes } from "./hashRoutes";


export function AboutUsTraining() {
    const training_img = static_url + homepage_media + "TrainingServices1.jpg";
    const rappelling_water_img = static_url + homepage_media + "RappellingIntoWater.jpg";
    const rappelling_img = static_url + homepage_media + "Rappelling.jpg";
    const classes = getClassIfNotHash(hashRoutes.training, "hidden", true);
    const classesOffered = (
        <ul className="class_offerings_list">
            <li>Haz-Mat: Awareness, Operations, and Technician Levels</li>
            <li>Rope Rescue: Awareness, Operations, and Technician Levels</li>
            <li>Confined Space: Awareness, Operations, and Technician Levels</li>
            <li>Fire Response and Brigade Training</li>
            <li>Medical Response Training, CPR Certification, First Aid Certification</li>
            <li>Terrorism Awareness</li>
            <li>Incident Command and Accountability Training</li>
            <li>Leadership Training</li>
        </ul>
    );

    const structuredTraining = (
        <div>
            <div>
                We train to exceed OSHA requirements and provide task book records of training and certifications. We also meet or exceed NFPA for each discipline.
            </div>
            <div className="about_us_subparagraph">
                We train your teams at your site to your needs. This practical, no-nonsense approach to training develops teams to achieve ideal proficiency and effectiveness.
            </div>
        </div>
    );

    return (
        <div className={"about_us_training " + classes}>
            <AboutUsImgAndText
                src={training_img}
                alt="Structured and Realistic"
                title="Structured and Realistic"
                body={structuredTraining} />
            <AboutUsImgAndText
                src={rappelling_img}
                alt="Variety of Classes"
                title="Variety of Classes"
                body={classesOffered} />
            <AboutUsImgAndText
                src={rappelling_water_img}
                alt="Qualified Instructors"
                title="Qualified Instructors"
                body="Our instructors come to you with a solid background in both education and real-life incidents. They are actively serving in fire departments and specialized rescue teams throughout the state and are NFPA certified instructors." />
        </div>
    );
}
