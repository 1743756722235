import React from "react";
import { getClassForHash } from "../../functions";
import { hashRoutes } from "./hashRoutes";
import "./InfoLink.scss";

type InfoLinkProps = {
    to: string;
    desc: string;
};
export function InfoLink(props: InfoLinkProps) {
    const allowBlank = props.to === hashRoutes.training ? true : false;
    const selectedClass = getClassForHash(props.to, "selected", allowBlank);
    return (
        <a
            className={"main_text about_us_link " + selectedClass}
            href={props.to}
        >
            {props.desc}
        </a>
    );
}
