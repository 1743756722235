import React from "react";
import { AboutUsRescue } from "./AboutUsRescue";
import { AboutUsSafety } from "./AboutUsSafety";
import { AboutUsTraining } from "./AboutUsTraining";
import { AboutUsLinks } from "./AboutUsLinks";
import "./AboutUs.scss";

export default function AboutUs() {

    return (
        <div className="about_us">
            <AboutUsLinks />
            <AboutUsTraining />
            <AboutUsSafety />
            <AboutUsRescue />
        </div>
    );
}