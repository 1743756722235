import React from "react";
import { hashRoutes } from "./hashRoutes";
import { InfoLink } from "./InfoLink";
import "./AboutUsLinks.scss";

export function AboutUsLinks() {
    return (
        <div className="about_us_link_container">
            <InfoLink
                to={hashRoutes.training}
                desc="Training" />
            <InfoLink
                to={hashRoutes.safety}
                desc="1st and 3rd Party Safety" />
            <InfoLink
                to={hashRoutes.rescue}
                desc="Rescue Standby" />
            {/* <InfoLink
                to={hashRoutes.products}
                desc="Products"
            /> */}
        </div>
    );
}
